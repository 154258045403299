import { EmailLoginForm } from './EmailLoginForm';
import { AuthenticationMethodType } from '../utils/authentication';
import { ApiKeyForm } from './ApiKeyForm';
import { useEnvironmentSettings } from '../contexts/EnvironmentSettingsContext';
import { updateSettingsField } from '../utils/environment.settings';

export const Authentication = () => {
  const { settings, setSettings } = useEnvironmentSettings();
  const types: AuthenticationMethodType[] = ['email', 'api-key'];

  const handleAuthMethodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (types.includes(selectedValue as AuthenticationMethodType)) {
      setSettings(
        updateSettingsField(settings, 'selectedAuthenticationMethod', selectedValue as AuthenticationMethodType)
      );
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Authentication</h2>

      <label style={{ fontWeight: 'bold', marginRight: '7px' }} htmlFor="method">
        Authentication method
      </label>
      <select id="method" value={settings.selectedAuthenticationMethod ?? ''} onChange={handleAuthMethodChange}>
        <option value="">-- Select an authentication method --</option>
        {types.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>

      {settings.selectedAuthenticationMethod === 'email' && <EmailLoginForm />}
      {settings.selectedAuthenticationMethod === 'api-key' && <ApiKeyForm />}
    </div>
  );
};
