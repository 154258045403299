export interface MeteorRefreshResponse {
  accessToken: string;
}

export async function fetchRefreshMeteorAccessToken(
  host: string,
  meteorToken: string,
  userId: string
): Promise<MeteorRefreshResponse> {
  const response = await fetch(`${host}/auth/meteor/refresh-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      meteorToken,
      userId,
    }),
  });

  if (response.ok) {
    return response.json();
  }

  const errorBody = await response.json();
  throw new Error(`Failed to refresh meteor access token: ${errorBody.message}`);
}

export interface MeteorLoginResponse {
  accessToken: string;
  meteorToken: string;
  userId: string;
}

export async function fetchMeteorLogin(host: string, email: string, password: string): Promise<MeteorLoginResponse> {
  const response = await fetch(`${host}/auth/meteor/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });

  if (response.ok) {
    return response.json();
  }

  const errorBody = await response.json();
  throw new Error(`Failed to login as meteor user: ${errorBody.message}`);
}
