import { EnvironmentSettings } from './environment.settings';
import { SubscriptionProtocol, UrlLoader } from '@graphql-tools/url-loader';
import { DocumentNode, Kind, parse } from 'graphql';
import { Fetcher, FetcherOpts, FetcherParams } from '@graphiql/toolkit';
import { isUnauthorizedError } from './errors';
import { fetchRefreshMeteorAccessToken } from './api.auth';

const urlLoader = new UrlLoader();

interface CreateFetcherOptions {
  settings: EnvironmentSettings;
  additionalHeaders: Record<string, string>;
  onUpdateAccessToken: (accessToken: string) => void;
}

// mostly copied from the GraphQL Yoga repo, to get the subscriptions over SSE to work: https://github.com/dotansimha/graphql-yoga/blob/94eb0ae65621087f7fd76f1ac6d7014da03d61d5/packages/graphiql/src/YogaGraphiQL.tsx
export function createFetcher({ settings, additionalHeaders, onUpdateAccessToken }: CreateFetcherOptions): Fetcher {
  const graphqlEndpoint = `${settings.host}/graphql`;

  let allHeaders: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  switch (settings.selectedAuthenticationMethod) {
    case 'api-key':
      allHeaders['X-API-KEY'] = settings.authenticationSettings.apiKey?.apiKey ?? '';
      break;
    case 'email':
      allHeaders['Authorization'] = `Bearer ${settings.authenticationSettings.email?.tokens?.accessToken}`;
      break;
    default:
      break;
  }

  if (settings.introspectionApiKey) {
    allHeaders['X-INTROSPECTION-KEY'] = settings.introspectionApiKey;
  }

  allHeaders = { ...allHeaders, ...additionalHeaders };

  const executor = createExecutor({
    endpoint: graphqlEndpoint,
    headers: allHeaders,
  });

  return async function fetcher(graphQLParams: FetcherParams, opts?: FetcherOpts) {
    const document = getOperationWithFragments(parse(graphQLParams.query), graphQLParams.operationName ?? undefined);

    const executeOptions = {
      document,
      operationName: graphQLParams.operationName ?? undefined,
      variables: graphQLParams.variables,
      extensions: {
        headers: opts?.headers,
      },
    };

    const exec = executor(executeOptions);

    // right now we only retry for promises
    if (exec instanceof Promise) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = await exec;

      // please note, somehow errors is not an array but an object with keys 0, 1, 2, etc.
      const firstError = result?.errors?.[0];

      if (
        settings.selectedAuthenticationMethod === 'email' &&
        isUnauthorizedError(firstError) &&
        settings.authenticationSettings.email?.tokens
      ) {
        console.info('Unauhorized, trying to refresh JWT');

        const { accessToken } = await fetchRefreshMeteorAccessToken(
          settings.host ?? '',
          settings.authenticationSettings.email.tokens.meteorToken,
          settings.authenticationSettings.email.tokens.userId
        );

        console.info('Refresh JWT', accessToken);

        onUpdateAccessToken(accessToken);

        // create a new executor with the new access token so we can retry the request
        const retryExecutor = createExecutor({
          endpoint: graphqlEndpoint,
          headers: {
            ...allHeaders,
            Authorization: `Bearer ${accessToken}`,
          },
        });

        return retryExecutor(executeOptions);
      }
    }

    return exec;
  };
}

interface CreateExecutorOptions {
  endpoint: string;
  headers: Record<string, string>;
}

function createExecutor({ endpoint, headers }: CreateExecutorOptions) {
  return urlLoader.getExecutorAsync(endpoint, {
    subscriptionsProtocol: SubscriptionProtocol.GRAPHQL_SSE,
    subscriptionsEndpoint: endpoint,
    credentials: 'same-origin',
    specifiedByUrl: true,
    directiveIsRepeatable: true,
    headers,
  });
}

/**
 * copied from yoga
 * @link https://github.com/dotansimha/graphql-yoga/blob/94eb0ae65621087f7fd76f1ac6d7014da03d61d5/packages/graphiql/src/YogaGraphiQL.tsx#L14C1-L33C3
 */
export const getOperationWithFragments = (document: DocumentNode, operationName?: string): DocumentNode => {
  const definitions = document.definitions.filter((definition) => {
    if (definition.kind === Kind.OPERATION_DEFINITION && operationName && definition.name?.value !== operationName) {
      return false;
    }
    return true;
  });

  return {
    kind: Kind.DOCUMENT,
    definitions,
  };
};
