import React from 'react';
import { AiOutlineCloudServer } from 'react-icons/ai';
import { EnvironmentSettingsForm } from '../components/EnvironmentSettingsForm';
import { Authentication } from '../components/Authentication';
import { EnvironmentSelector } from '../components/EnvironmentSelector';

const EnvironmentPlugin = () => {
  return (
    <div>
      <EnvironmentSelector />
      <EnvironmentSettingsForm />
      <Authentication />
    </div>
  );
};

export const environmentPlugin = {
  title: 'Environment',
  icon: AiOutlineCloudServer,
  content: EnvironmentPlugin,
};
