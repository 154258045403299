import { AuthenticationMethodApiKey, AuthenticationMethodEmail, AuthenticationMethodType } from './authentication';
import { Environment, isEnvironment } from './environment';
import { storage } from './storage';
import { cloneDeep, set } from 'lodash';

export interface EnvironmentSettings {
  environment: Environment;
  host?: string;
  introspectionApiKey?: string;
  selectedAuthenticationMethod?: AuthenticationMethodType;
  /** settings for all authentication methods */
  authenticationSettings: {
    email?: AuthenticationMethodEmail;
    apiKey?: AuthenticationMethodApiKey;
  };
}

export interface EnvironmentDefaults {
  host?: string;
  introspectionSecretUrl?: string;
  requiresPortForwarding?: boolean;
}

export const defaultEnvironmentSettings: Record<Environment, EnvironmentDefaults> = {
  'localhost.public': {
    host: 'http://localhost:4000',
  },
  'localhost.internal': {
    host: 'http://localhost:4001',
  },
  'dev.public': {
    host: 'https://graphql-api.dev.lessonup.dev',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/lessonup-dev-introspection-api-key/versions?project=lessonup-test',
  },
  'dev.internal': {
    host: 'http://localhost:4101',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/lessonup-dev-introspection-api-key/versions?project=lessonup-test',
  },
  'team-a.public': {
    host: 'https://graphql-api.team-a.lessonup.dev',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/team-a-introspection-api-key/versions?project=lessonup-test',
  },
  'team-a.internal': {
    host: 'https://graphql-api-internal.team-a.lessonup.dev',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/team-a-introspection-api-key/versions?project=lessonup-test',
  },
  'team-b.public': {
    host: 'https://graphql-api.team-b.lessonup.dev',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/team-b-introspection-api-key/versions?project=lessonup-test',
  },
  'team-b.internal': {
    host: 'https://graphql-api-internal.team-b.lessonup.dev',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/team-b-introspection-api-key/versions?project=lessonup-test',
  },
  'team-c.public': {
    host: 'https://graphql-api.team-c.lessonup.dev',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/team-c-introspection-api-key/versions?project=lessonup-test',
  },
  'team-c.internal': {
    host: 'https://graphql-api-internal.team-c.lessonup.dev',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/team-c-introspection-api-key/versions?project=lessonup-test',
  },
  'test.public': {
    host: 'https://graphql-api.test.lessonup.dev',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/lu-test-introspection-api-key/versions?project=lessonup-test',
  },
  'test.internal': {
    host: 'http://localhost:4201',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/lu-test-introspection-api-key/versions?project=lessonup-test',
    requiresPortForwarding: true,
  },
  'staging.public': {
    host: 'https://graphql-api-staging.lessonup.com',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/lu-prod-introspection-api-key/versions?project=lessonup-live',
  },
  'staging.internal': {
    host: 'http://localhost:4301',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/lu-prod-introspection-api-key/versions?project=lessonup-live',
    requiresPortForwarding: true,
  },
  'prod.public': {
    host: 'https://graphql-api.lessonup.com',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/lu-prod-introspection-api-key/versions?project=lessonup-live',
  },
  'prod.internal': {
    host: 'http://localhost:4401',
    introspectionSecretUrl:
      'https://console.cloud.google.com/security/secret-manager/secret/lu-prod-introspection-api-key/versions?project=lessonup-live',
    requiresPortForwarding: true,
  },
  other: {},
};

export function defaultsForEnvironment(environment: Environment): EnvironmentDefaults {
  return defaultEnvironmentSettings[environment];
}

export function mergeEnvironmentSettingsWithDefaults(settings: EnvironmentSettings): EnvironmentSettings {
  const defaults = defaultsForEnvironment(settings.environment);

  return {
    ...settings,
    host: settings.host || defaults.host,
  };
}

export const initialEnvironment = () => {
  const envFromQS = new URLSearchParams(globalThis.location.search).get('env');
  if (isEnvironment(envFromQS)) return envFromQS;

  const hostFromQS = new URLSearchParams(globalThis.location.search).get('host');

  // try to find the hostname in the default env settings
  if (hostFromQS) {
    const result = Object.entries(defaultEnvironmentSettings).find(([, settings]) =>
      settings.host?.includes(hostFromQS)
    );
    if (result) return result[0] as Environment;
  }

  return storage.lastSelectedEnvironment || 'dev.public';
};

export const settingsForEnvironment = (environment: Environment): EnvironmentSettings => {
  const settings = storage.environmentSettings(environment) ?? {
    environment,
    authenticationSettings: {},
  };
  const defaultSettings = defaultsForEnvironment(environment);

  return {
    ...settings,
    host: settings.host || defaultSettings.host,
  };
};

// we should remove this helper
export const updateSettingsField = <Path extends keyof EnvironmentSettings>(
  settings: EnvironmentSettings,
  fieldPath: Path,
  value: EnvironmentSettings[Path]
): EnvironmentSettings => {
  const newSettings = cloneDeep(settings);
  set(newSettings, fieldPath, value);
  return newSettings;
};
