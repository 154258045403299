export const allEnviroments = [
  'localhost.public',
  'localhost.internal',
  'dev.public',
  'dev.internal',
  'team-a.public',
  'team-a.internal',
  'team-b.public',
  'team-b.internal',
  'team-c.public',
  'team-c.internal',
  'test.public',
  'test.internal',
  'staging.public',
  'staging.internal',
  'prod.public',
  'prod.internal',
  'other',
] as const;

export type Environment = typeof allEnviroments[number];

export function isEnvironment(value: unknown): value is Environment {
  return allEnviroments.includes(value as Environment);
}
