import './App.css';
import 'graphiql/graphiql.css';
import '@graphiql/plugin-explorer/dist/style.css';
import { GraphiQL } from 'graphiql';
import { useCallback, useMemo, useState } from 'react';
import { Fetcher } from '@graphiql/toolkit';
import { explorerPlugin } from '@graphiql/plugin-explorer';
import { storage } from './utils/storage';
import {
  EnvironmentSettings,
  initialEnvironment,
  settingsForEnvironment,
  updateSettingsField,
} from './utils/environment.settings';
import { EnvironmentContext } from './contexts/EnvironmentContext';
import { EnvironmentSettingsContext } from './contexts/EnvironmentSettingsContext';
import { Environment } from './utils/environment';
import { createFetcher } from './utils/fetcher';
import { environmentPlugin } from './plugins/EnvironmentPlugin';

type Headers = Record<string, string>;

const App = () => {
  const [environment, setEnvironment] = useState<Environment>(initialEnvironment());
  const [settings, setSettings] = useState<EnvironmentSettings>(settingsForEnvironment(initialEnvironment()));

  const [additionalHeaders, setAdditionalHeaders] = useState<Headers>({});

  const handleChangeEnvironment = (updateValue: Environment) => {
    storage.lastSelectedEnvironment = updateValue;
    setEnvironment(updateValue);
    setSettings(settingsForEnvironment(updateValue));
  };

  const handleUpdateSettings = useCallback(
    (updateValue: EnvironmentSettings) => {
      storage.setEnvironmentSettings(updateValue);
      setSettings(updateValue);
    },
    [setSettings]
  );

  const handleChangeHeaders = (inputFieldValue: string) => {
    try {
      // invalid JSON will throw an error, which will happen while editing the headers in GraphiQL
      setAdditionalHeaders(JSON.parse(inputFieldValue));
    } catch (error) {
      // do nothing
    }
  };

  const fetcher: Fetcher = useMemo(() => {
    return createFetcher({
      settings,
      additionalHeaders,
      onUpdateAccessToken: (accessToken) => {
        if (settings.authenticationSettings.email?.tokens) {
          // ugly, refactor to a clone deep
          const newSettings = updateSettingsField(settings, 'authenticationSettings', {
            ...settings.authenticationSettings,
            email: {
              ...settings.authenticationSettings.email,
              tokens: {
                ...settings.authenticationSettings.email.tokens,
                accessToken,
              },
            },
          });
          handleUpdateSettings(newSettings);
        }
      },
    });
  }, [additionalHeaders, settings, handleUpdateSettings]);

  return (
    <EnvironmentContext.Provider value={{ environment, setEnvironment: handleChangeEnvironment }}>
      <EnvironmentSettingsContext.Provider value={{ settings, setSettings: handleUpdateSettings }}>
        <GraphiQL
          onEditHeaders={(value) => handleChangeHeaders(value)}
          headers={JSON.stringify(additionalHeaders, null, 2)}
          visiblePlugin={storage.lastSelectedEnvironment ? undefined : environmentPlugin}
          shouldPersistHeaders={true}
          plugins={[
            explorerPlugin({
              showAttribution: true,
            }),
            environmentPlugin,
          ]}
          fetcher={fetcher}
        />
      </EnvironmentSettingsContext.Provider>
    </EnvironmentContext.Provider>
  );
};

export default App;
