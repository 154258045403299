import { useEnvironmentSettings } from '../contexts/EnvironmentSettingsContext';
import { EnvironmentSettings, defaultsForEnvironment, updateSettingsField } from '../utils/environment.settings';

type FormData = NonNullable<Pick<EnvironmentSettings, 'host' | 'introspectionApiKey'>>;

const mapFormData = (settings: EnvironmentSettings): FormData => ({
  host: settings.host ?? '',
  introspectionApiKey: settings.introspectionApiKey ?? '',
});

export const EnvironmentSettingsForm = () => {
  const { settings, setSettings } = useEnvironmentSettings();

  const formData = mapFormData(settings);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newSettings = updateSettingsField(settings, name as keyof EnvironmentSettings, value);
    setSettings(newSettings);
  };

  const handleSubmit = (event: React.FormEvent) => event.preventDefault();

  const { introspectionSecretUrl, requiresPortForwarding } = defaultsForEnvironment(settings.environment);

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
        <label style={{ fontWeight: 'bold' }} htmlFor="host">
          Host:
        </label>
        <input
          readOnly={!['localhost', 'other'].includes(settings.environment)}
          type="text"
          id="host"
          name="host"
          value={formData.host}
          onChange={handleChange}
          required
        />
        {requiresPortForwarding && (
          <span>
            Can only be accessed via port-forwarding. Run <em>lu port-forward</em>
          </span>
        )}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
        <label style={{ fontWeight: 'bold' }} htmlFor="introspectionApiKey">
          Introspection Key:
        </label>
        <input
          type="password"
          id="introspectionApiKey"
          name="introspectionApiKey"
          value={formData.introspectionApiKey}
          onChange={handleChange}
        />

        <span>
          This key will be send as the <em>X-INTROSPECTION-KEY</em> header and will allow you to introspect the API.
          {introspectionSecretUrl && (
            <>
              The secret can be found{' '}
              <a target="_blank" href={introspectionSecretUrl} rel="noreferrer">
                here
              </a>
            </>
          )}
        </span>
      </div>
    </form>
  );
};
