import { createContext, useContext } from 'react';
import { EnvironmentSettings } from '../utils/environment.settings';

interface EnvironmentContextProps {
  settings?: EnvironmentSettings;
  setSettings?: (environment: EnvironmentSettings) => void;
}

export const EnvironmentSettingsContext = createContext<EnvironmentContextProps>({});

export function useEnvironmentSettings(): Required<EnvironmentContextProps> {
  const { settings, setSettings } = useContext(EnvironmentSettingsContext);

  if (!settings || !setSettings) {
    throw new Error('EnvironmentContext: settings or setSettings not set via provider');
  }

  return { settings, setSettings };
}
