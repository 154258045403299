import { createContext, useContext } from 'react';
import { Environment } from '../utils/environment';

interface EnvironmentContextProps {
  environment?: Environment;
  setEnvironment?: (environment: Environment) => void;
}

export const EnvironmentContext = createContext<EnvironmentContextProps>({});

export function useEnvironment(): Required<EnvironmentContextProps> {
  const { environment, setEnvironment } = useContext(EnvironmentContext);

  if (!environment || !setEnvironment) {
    throw new Error('EnvironmentContext: settings or setSettings not set via provider');
  }

  return { environment, setEnvironment };
}
