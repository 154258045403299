import { useEnvironmentSettings } from '../contexts/EnvironmentSettingsContext';
import { EnvironmentSettings, updateSettingsField } from '../utils/environment.settings';

interface ApiKeyFormData {
  apiKey: string;
}

export const ApiKeyForm = () => {
  const { settings, setSettings } = useEnvironmentSettings();
  const formData = mapFormData(settings);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings(
      updateSettingsField(settings, 'authenticationSettings', {
        apiKey: {
          apiKey: event.target.value,
        },
      })
    );
  };

  return (
    <div>
      <h3>Api key authentication</h3>
      <form style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ fontWeight: 'bold' }} htmlFor="apiKey">
            Api Key
          </label>
          <input name="apiKey" value={formData.apiKey} type="password" placeholder="********" onChange={handleChange} />
          <span>
            Will be added to the <em>X-API-KEY</em> header
          </span>
        </div>
      </form>
    </div>
  );
};

const mapFormData = (settings: EnvironmentSettings): ApiKeyFormData => {
  return {
    apiKey: settings.authenticationSettings.apiKey?.apiKey ?? '',
  };
};
