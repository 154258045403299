import { useEnvironment } from '../contexts/EnvironmentContext';
import { allEnviroments, isEnvironment } from '../utils/environment';

export const EnvironmentSelector = () => {
  const { environment, setEnvironment } = useEnvironment();

  const handleEnvironmentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (isEnvironment(selectedValue)) {
      setEnvironment?.(selectedValue);
    }
  };

  return (
    <div>
      <label style={{ fontWeight: 'bold', marginRight: '7px' }} htmlFor="environment">
        Environment
      </label>
      <select id="environment" value={environment} onChange={handleEnvironmentChange}>
        <option value="">-- Select an environment --</option>
        {allEnviroments.map((env) => (
          <option key={env} value={env}>
            {env}
          </option>
        ))}
      </select>
    </div>
  );
};
