import { useState } from 'react';
import { useEnvironmentSettings } from '../contexts/EnvironmentSettingsContext';
import { EnvironmentSettings, updateSettingsField } from '../utils/environment.settings';
import { fetchMeteorLogin } from '../utils/api.auth';

interface EmailPasswordFormData {
  email: string;
  password: string;
}

export const EmailLoginForm = () => {
  const { settings, setSettings } = useEnvironmentSettings();
  const [formData, setFormData] = useState(initialFormData(settings));
  const [error, setError] = useState<Error | undefined>(undefined);

  const handleLogin = async (email: string, password: string) => {
    try {
      setError(undefined);
      const data = await fetchMeteorLogin(settings.host ?? '', email, password);

      setSettings(
        updateSettingsField(settings, 'authenticationSettings', {
          ...settings.authenticationSettings,
          email: {
            email,
            password,
            tokens: {
              accessToken: data.accessToken,
              meteorToken: data.meteorToken,
              userId: data.userId,
            },
          },
        })
      );
    } catch (error: unknown) {
      console.error(error);
      setError(error instanceof Error ? error : new Error(`Error: ${error}`));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((oldValue) => ({
      ...oldValue,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void handleLogin(formData.email, formData.password);
  };

  return (
    <div>
      <h3>User login</h3>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ fontWeight: 'bold' }} htmlFor="email">
            E-mail
          </label>
          <input name="email" value={formData.email} placeholder="me+dev@lessonup.com" onChange={handleChange} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ fontWeight: 'bold' }} htmlFor="password">
            Password
          </label>
          <input
            name="password"
            value={formData.password}
            type="password"
            placeholder="********"
            onChange={handleChange}
          />
        </div>
        {error ? <div style={{ color: 'red' }}>{error.message}</div> : null}
        <button type="submit">Login</button>
      </form>

      <h3>Logged in user</h3>
      <p>Information below is the user currently logged in, login again to fetch new tokens.</p>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>User Id</span>
          <span>{settings.authenticationSettings.email?.tokens?.userId ?? 'none'}</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>Access Token</span>
          <span>
            <em>Send on every request automatically</em>
          </span>
          <span>{settings.authenticationSettings.email?.tokens?.accessToken}</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>Meteor Token</span>
          <span>{settings.authenticationSettings.email?.tokens?.meteorToken ?? 'none'}</span>
        </div>
      </div>
    </div>
  );
};

const initialFormData = (settings: EnvironmentSettings): EmailPasswordFormData => {
  return {
    email: settings.authenticationSettings.email?.email ?? '',
    password: settings.authenticationSettings.email?.password ?? '',
  };
};
