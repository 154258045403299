import { Environment, isEnvironment } from './environment';
import type { EnvironmentSettings } from './environment.settings';

enum Keys {
  SETTINGS = 'settings',
  LAST_SELECTED_ENV = 'lastSelectedEnvironment',
}

export class Storage {
  public get lastSelectedEnvironment(): Environment | null {
    const env = localStorage.getItem(Keys.LAST_SELECTED_ENV);
    return isEnvironment(env) ? env : null;
  }

  public set lastSelectedEnvironment(value: Environment) {
    localStorage.setItem(Keys.LAST_SELECTED_ENV, value);
  }

  public environmentSettings(environment: Environment): EnvironmentSettings | null {
    return this.getJsonValue(environment, Keys.SETTINGS);
  }

  public setEnvironmentSettings(value: EnvironmentSettings) {
    return this.setJsonValue(value.environment, Keys.SETTINGS, value);
  }

  private getJsonValue<T>(env: Environment, key: Keys): T | null {
    const value = localStorage.getItem(`${env}.${key}`);
    if (!value) return null;

    try {
      return JSON.parse(value);
    } catch (error) {
      console.error(`Failed to parse ${value} for ${key} from localStorage`, error);
      return null;
    }
  }

  private setJsonValue(env: Environment, key: Keys, value: unknown): void {
    localStorage.setItem(`${env}.${key}`, JSON.stringify(value));
  }
}

export const storage = new Storage();
